import styled from 'styled-components';

import {colors} from '../views.constants';

const DecodeBox = styled.div`
position: fixed;
border: 3px solid ${colors.YGGIO_GREEN};
right: 10px;
bottom: 2px;
border-radius: 4px;
margin: 2px;
z-index: 2;
height: 40%;
width: 50%;
min-width: 450px;
min-height: 250px;
background: ${colors.WHITE};
`;

const DecodeBoxClosed = styled.div`
position: fixed;
display: flex;
cursor: pointer;
right: 10px;
bottom: 2px;
padding: 2px;
z-index: 2;
border-radius: 4px;
vertical-align: middle;
margin: 2px;
color: ${colors.WHITE};
background: ${colors.YGGIO_BUTTON};
`;

const DecodeBoxTopBar = styled.div`
  width: 100%;
  cursor: pointer;
  display: flex;
  marginTop:0;
  background: ${colors.YGGIO_GREEN}
  color: ${colors.WHITE}
`;

const DecodeBoxBottomBar = styled.div`
  position: absolute;
  cursor: pointer;
  bottom:0;
  width:100%;
  text-align:center;
  background: ${colors.YGGIO_GREEN}
  color: ${colors.GREY_03}
`;


export {
    DecodeBox,
    DecodeBoxClosed,
    DecodeBoxTopBar,
    DecodeBoxBottomBar
};
